import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import { GoogleOAuthProvider } from '@react-oauth/google';
import Routes from './routes';
import theme from './theme';
import Layout from './components/Layout';
import { useAppApolloClient } from './config/apolloClient';
import { CalendarProvider } from "contexts/CalendarContext";
import ErrorBoundary from 'components/ErrorBoundary';
import AnalyticsListener from 'components/AnalyticsListener';
import DragLayer from 'components/DragLayer';
import ScrollingContainer from 'components/ScrollingContainer';
import { SearchProvider } from './contexts/SearchContext';

const generateClassName = createGenerateClassName();

function App() {
  const apolloClient = useAppApolloClient();

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <HelmetProvider>
        <ApolloProvider client={apolloClient}>
          <StylesProvider generateClassName={generateClassName} injectFirst>
            <ThemeProvider theme={theme}>
              <StyledThemeProvider theme={theme}>
                <AnalyticsListener>
                  <SearchProvider initialValue={''}>
                    <CalendarProvider>
                      <DndProvider options={HTML5toTouch}>
                        <DragLayer />
                        <Layout>
                          <ScrollingContainer>
                            <ErrorBoundary>
                              <Routes />
                            </ErrorBoundary>
                          </ScrollingContainer>
                        </Layout>
                      </DndProvider>
                    </CalendarProvider>
                  </SearchProvider>
                </AnalyticsListener>
              </StyledThemeProvider>
            </ThemeProvider>
          </StylesProvider>
        </ApolloProvider>
      </HelmetProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
